import React, { ReactElement } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Box,
  Text,
  Lockup,
  ButtonLink,
  rem,
  IconHolistic,
  IconGift,
  IconBcorpLogo,
  IconTrust,
  IconProps,
} from '@butcherbox/freezer'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import BackgroundImage from 'gatsby-background-image'
import ValuesCard from './ValuesCard'
import ActionCard from './ActionCard'
import ReportsList from '../Reports'
import * as Styles from './AboutUs.css'

export default function AboutUs() {
  const {
    heroImage,
    animalsImage,
    farmersImage,
    planetImage,
    workersImage,
    communityImage,
    ourStoryImage,
  } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "about-us-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      animalsImage: file(
        relativePath: { eq: "about-us-values-card-animals.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      farmersImage: file(
        relativePath: { eq: "about-us-values-card-farmers.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      planetImage: file(
        relativePath: { eq: "about-us-values-card-planet.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      workersImage: file(
        relativePath: { eq: "about-us-values-card-workers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      communityImage: file(
        relativePath: { eq: "about-us-values-card-community.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ourStoryImage: file(relativePath: { eq: "about-us-our-story.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const BCorpIcon: ReactElement<IconProps> = (
    <IconBcorpLogo
      customColor={{ base: 'white' }}
      marginBottom={12}
      marginLeft={0}
      pixelSize={74}
      size="fixed"
    />
  )
  const GiftIcon: ReactElement<IconProps> = (
    <IconGift
      customColor={{ base: 'white', accent: 'white' }}
      marginBottom={12}
      size="regular"
    />
  )
  const TrustIcon: ReactElement<IconProps> = (
    <IconTrust
      customColor={{ base: 'white', accent: 'white' }}
      marginBottom={12}
      size="regular"
    />
  )
  const HolisticIcon: ReactElement<IconProps> = (
    <IconHolistic
      customColor={{ base: 'white' }}
      marginBottom={12}
      size="regular"
    />
  )

  return (
    <UnauthenticatedLayout>
      {/* Hero */}

      <section aria-label="About ButcherBox">
        <BackgroundImage
          alt="A family of four leaning on a wooden fence looking off into the sunset"
          className={Styles.HeroImage}
          fluid={heroImage.childImageSharp.fluid}
        >
          <Box className={Styles.HeroTextWrap}>
            <Text
              color="white"
              component="h1"
              m="auto"
              textAlign="center"
              variant="DisplayOne"
            >
              About ButcherBox
            </Text>
          </Box>
        </BackgroundImage>

        {/* Our Mission */}

        <Box className={Styles.OurMissionSection}>
          <Box className={Styles.OurMission}>
            <Lockup>
              <Text color="white" variant="Eyebrow">
                Delivering high-quality meat is just the beginning
              </Text>
              <Text color="white" variant="Thematic">
                Our company began with a simple mission to make high-quality
                meat more accessible to all. We soon discovered that meant doing
                something not so simple at all: rethinking our food system.
              </Text>
              <Text color="white" variant="Thematic">
                We&rsquo;re in pursuit of a better way, one that&rsquo;s focused
                on animal welfare, supporting farmers, treating our planet with
                respect, and upholding diversity, equity, and inclusion across
                our business.
              </Text>
            </Lockup>
          </Box>
        </Box>
      </section>

      {/* Values */}

      <Box className={Styles.ValuesSection} component="section">
        <Box paddingBottom={40} paddingTop={60}>
          <Text component="h2" textAlign="center" variant="DisplayTwo">
            What We Value
          </Text>
        </Box>
        <ValuesCard
          image={animalsImage}
          imageAlt="A group of humanely raised piglets running towards the camera"
          orientation="imageRight"
          theme="wine"
        >
          <Lockup>
            <Text component="h3" variant="H2Bold">
              Animals
            </Text>
            <Text component="p" variant="H3Regular">
              We care about the lives of animals. We&rsquo;re proud to partner
              with folks who raise animals and harvest seafood with safety,
              sustainability, natural tendencies, and comfort in mind.
            </Text>
          </Lockup>
        </ValuesCard>
        <ValuesCard
          image={farmersImage}
          imageAlt="A ButcherBox farmer smiling at the camera"
          orientation="imageLeft"
          theme="openSea"
        >
          <Lockup>
            <Text component="h3" variant="H2Bold">
              Farmers & Fishermen
            </Text>
            <Text component="p" variant="H3Regular">
              These are the people who harness the power of nature&mdash;we
              believe they should earn fair and predictable living wages. We
              support farmers and fishermen of all walks of life, and
              we&rsquo;re dedicated to increasing racial equity in the industry.
            </Text>
          </Lockup>
        </ValuesCard>
        <ValuesCard
          image={planetImage}
          imageAlt="A ButcherBox farmer, examining the soil"
          orientation="imageRight"
          theme="honeyMustard"
        >
          <Lockup>
            <Text component="h3" variant="H2Bold">
              Planet
            </Text>
            <Text component="p" variant="H3Regular">
              Our natural resources are limited, so we want to use them
              responsibly. We&rsquo;re working to support sustainable fishing
              and agricultural practices, improve soil health, minimize our
              carbon footprint, and inspire our partners to do the same.
            </Text>
          </Lockup>
        </ValuesCard>
        <ValuesCard
          image={workersImage}
          imageAlt="A fulfillment center worker packing together boxes of ButcherBox meat"
          orientation="imageLeft"
          theme="pesto"
        >
          <Lockup>
            <Text component="h3" variant="H2Bold">
              Workers
            </Text>
            <Text component="p" variant="H3Regular">
              Our partners and our employees are one team&mdash;and we&rsquo;re
              ever thankful for that team. We believe in creating an environment
              where all of our workers are treated equitably and that their
              diverse identities and backgrounds are celebrated.
            </Text>
          </Lockup>
        </ValuesCard>
        <ValuesCard
          image={communityImage}
          imageAlt="A family sitting around a dinner table"
          orientation="imageRight"
          theme="wine"
        >
          <Lockup>
            <Text component="h3" variant="H2Bold">
              Community
            </Text>
            <Text component="p" variant="H3Regular">
              We want to make high-quality meat more accessible to all, raising
              the bar for the meat our members eat and serve the ones they love.
            </Text>
          </Lockup>
        </ValuesCard>
      </Box>

      {/* Actions */}

      <Box className={Styles.ActionsSection} component="section">
        <Box className={Styles.ActionsWrap}>
          <Box className={Styles.Actions}>
            <Lockup>
              <Text
                color="white"
                component="h2"
                id="heading-actions"
                variant="DisplayTwo"
              >
                Actions Speak Louder Than Words
              </Text>
              <Text color="white" component="p" variant="H3Regular">
                Anyone can talk about their values. The true differentiator is
                what happens next—how these words come to life. We recognize
                we're on a lifelong journey, and the following steps are where
                we start.
              </Text>
            </Lockup>
          </Box>
          <Box>
            <Box className={Styles.ActionsGrid}>
              <ActionCard
                header="A holistic approach"
                renderIcon={() => HolisticIcon}
              >
                <Text color="white" component="p" variant="H3Regular">
                  Understanding the connections between farmers, animals, the
                  planet, our team, and our members is where it all begins.
                </Text>
              </ActionCard>
              <ActionCard header="A giving mindset" renderIcon={() => GiftIcon}>
                <Text color="white" component="p" variant="H3Regular">
                  We carefully partner with charitable organizations to make the
                  most impact possible. Please{' '}
                  <a
                    className={Styles.Link}
                    href="mailto:charity@butcherbox.com"
                  >
                    contact us
                  </a>{' '}
                  for more information on giving.
                </Text>
              </ActionCard>
            </Box>
            <Box className={Styles.ActionsGrid}>
              <ActionCard
                header="Third-party certifications"
                iconProps={{ style: { position: 'relative', left: -14 } }}
                renderIcon={() => BCorpIcon}
              >
                <Text color="white" component="p" variant="H3Regular">
                  Don&rsquo;t take our word for it. Third-party certifications,
                  including{' '}
                  <a
                    className={Styles.Link}
                    href="https://justcook.butcherbox.com/why-butcherbox-became-b-corp/"
                    target="_blank"
                  >
                    B Corporation Certification
                  </a>
                  , and various animal welfare organizations, including the{' '}
                  <a
                    className={Styles.Link}
                    href="https://justcook.butcherbox.com/committing-to-more-humane-chicken-standards-continuing-our-quality-driven-mission/"
                    target="_blank"
                  >
                    Global Animal Partnership and ASPCA
                  </a>
                  , hold us accountable.
                </Text>
              </ActionCard>
              <ActionCard
                header="Transparency and reporting"
                renderIcon={() => TrustIcon}
              >
                <Text color="white" component="p" variant="H3Regular">
                  We&rsquo;re on a journey, and we&rsquo;re learning along the
                  way. We invite everyone to follow—we&rsquo;ll be{' '}
                  <a
                    className={Styles.Link}
                    href="https://justcook.butcherbox.com/our-mission/"
                    target="_blank"
                  >
                    sharing updates
                  </a>
                  , and we&rsquo;ll be open and honest about our progress. View
                  our annual Social and Environmental Responsibility Reports
                  below.
                </Text>
                <ReportsList />
              </ActionCard>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Our Story */}

      <Box aria-labelledby="heading-our-story" component="section">
        <BackgroundImage
          className={Styles.OurStoryImage}
          fluid={ourStoryImage.childImageSharp.fluid}
        />
        <Box background="white" className={Styles.OurStory} m="auto">
          <Lockup>
            <Text
              color="black"
              component="h2"
              id="heading-our-story"
              textAlign="center"
              variant="DisplayTwo"
            >
              More About Our Story
            </Text>
            <Text component="p" variant="H3Regular">
              Motivated to find a better way to feed his family, ButcherBox
              founder Mike Salguero connected with a local cattle farmer back in
              2015. The experience that followed, picking a share of the 100 %
              grass-fed beef spread across the farmer&rsquo;s living room, led
              him to two revelations:
            </Text>
          </Lockup>

          <Box className={Styles.OurStoryQuoteWrap}>
            <Lockup variant="default">
              <Text component="p" variant="H3Bold">
                “The taste is better.”
              </Text>
              <Text component="p" variant="H3Regular">
                Grass-fed, grass-finished beef tastes amazing—especially the
                cuts he hadn&rsquo;t been able to find in local food chains and
                neighborhood supermarkets.
              </Text>
              <Text component="p" variant="H3Bold">
                “The current system isn&rsquo;t working.”
              </Text>
              <Text component="p" variant="H3Regular">
                There had to be an easier way to find high-quality, humanely
                raised meat.
              </Text>
            </Lockup>
          </Box>
          <Text component="p" variant="H3Regular">
            And so, with a mission to make high-quality meat more accessible to
            all, Mike founded ButcherBox. Since then, the company has grown and
            expanded the products delivered to doorsteps across America, all
            while taking steps to improve the entire food system.
          </Text>
        </Box>
      </Box>

      {/* Join */}

      <Box
        aria-labelledby="heading-join-our-team"
        background="openSea"
        color="white"
        component="section"
        px={24}
        py={60}
        textAlign="center"
      >
        <Box m="auto" style={{ maxWidth: rem(700) }} w="100%">
          <Lockup variant="default">
            <Text color="white" variant="DisplayTwo">
              Join Our Team
            </Text>
            <Text color="white" component="p" variant="H2Bold">
              Our team is made up of people who collaborate and support one
              another. We&rsquo;re always looking for outstanding folks to join
              our mission. Visit our careers page to learn more.
            </Text>
            <ButtonLink href="/careers/" size="standard" variant="primary">
              View Careers
            </ButtonLink>
          </Lockup>
        </Box>
      </Box>
    </UnauthenticatedLayout>
  )
}
