import { List, ListItem } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { styled } from 'design'

export default function ReportsList() {
  const query = useStaticQuery(graphql`
    query {
      allContentfulReport(sort: { fields: title, order: ASC }) {
        nodes {
          accessibleText
          title
          reportPdf {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const reports = query.allContentfulReport.nodes
  const LinkSimple = styled.a`
    text-decoration: underline;
    color: inherit;
  `

  return (
    <List variant="unordered">
      {reports &&
        reports.map((report, i) => (
          <ListItem key={`report-${i}`}>
            <LinkSimple
              href={report.reportPdf.file.url}
              target="_blank"
              title={`${report.accessibleText} (PDF in new window)`}
            >
              {report.title}
            </LinkSimple>
          </ListItem>
        ))}
    </List>
  )
}
