import React from 'react'
import { Box } from '@butcherbox/freezer'
import BackgroundImage from 'gatsby-background-image'
import * as Styles from './ValuesCard.css'

const ValuesCard: React.FC<
  {
    image: {
      childImageSharp: {
        fluid: React.ComponentPropsWithRef<typeof BackgroundImage>['fluid']
      }
    }
    imageAlt: string
    orientation: 'imageLeft' | 'imageRight'
    theme?: 'wine' | 'honeyMustard' | 'pesto' | 'openSea'
  } & React.ComponentPropsWithRef<typeof Box>
> = ({ image, imageAlt, theme, orientation, children }) => {
  return (
    <Box className={Styles.ValuesCard[orientation]}>
      <Box className={Styles.ImageWrap}>
        <BackgroundImage
          alt={imageAlt}
          className={Styles.BackgroundImage}
          fluid={image.childImageSharp.fluid}
        />
      </Box>
      <Box className={Styles.TextWrap[orientation]}>
        <Box className={Styles.ValuesBars[theme]}>{children}</Box>
      </Box>
    </Box>
  )
}

export default ValuesCard
