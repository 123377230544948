import { Box, Text, Lockup, IconProps, BoxProps } from '@butcherbox/freezer'
import React, { ReactElement } from 'react'
import * as Styles from './ActionCard.css'

const ActionCard: React.FC<{
  renderIcon: () => ReactElement<IconProps>
  header: React.ReactNode
  iconProps?: BoxProps
}> = ({ children, header, iconProps, renderIcon, ...props }) => {
  return (
    <Box className={Styles.actionCard} {...props}>
      <Box {...iconProps}> {renderIcon()}</Box>
      <Lockup variant="default">
        <Text color="white" component="p" variant="H3Bold">
          {header}
        </Text>
        {children}
      </Lockup>
    </Box>
  )
}

export default ActionCard
